<script src="../../../myprofilplus/analytics/analytics.component.ts"></script>
<app-topbar moduleSlug="commercial-actions">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/commercial-actions']">Actions commerciales</a></li>
        <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Édition d'une demande d'accord</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Création d'une nouvelle demande d'accord</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup && !loading; else templateName">
  <form class="form" [formGroup]="editFormGroup" *ngIf="!editMode.active || (editMode.active && editMode.data)">

    <div class="col-lg-12" *ngIf="editMode.active"><strong style="color:#d5135b;">N° accord : {{actionId}}-{{agreementId}}</strong></div>

    <!-- BLOC "Sélection de l'action commerciale" -->
    <div class="row ppp-agreements-data">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">autorenew</i>
            </div>
            <h4 class="card-title">Sélection de l'action commerciale</h4>
          </div>
          <div class="card-body">
            <!-- "Rechercher une action commerciale" -->
            <div class="form-group" *ngIf="commercialActions; else templateElse2">
              <app-datalist [disabled]="editMode.active" label="Rechercher une action commerciale" (selected)="onSelectedAction($event)" [items]="commercialActions" [preselect]="preselectCommercialActionIndex" [property]="'name'"></app-datalist>
            </div>
            <ng-template #templateElse2>
              <app-loading></app-loading>
            </ng-template>
            <!-- "Agence" -->
            <div class="form-group" [ngClass]="{'has-danger': submitted && this.preselectCommercialAction && (f['customer_agency'] && f['customer_agency'].errors || editFormGroup.value.customer_agency === null), 'is-required': this.preselectCommercialAction}">
              <ng-container *ngIf="this.preselectCommercialAction ">
                <app-datalist *ngIf="!loadingCommercialActionCount; else loadingAgenciesList" [items]="agenciesList" [preselect]="preselectAgency" [disabled]="editMode.active" [property]="'name'" (selected)="onSelectedAgency($event)">
                </app-datalist>
                <ng-template #loadingAgenciesList>
                  En attente des informations de l'action commerciale sélectionnée...
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- INTERROGATOR INFORMATION -->
      <div class="col-lg-6 col-md-12" *ngIf="preselectCommercialAction !== null && !loadingCommercialActionCount">

        <!-- Pour les AC "Standard" (0) -->
        <ng-container  *ngIf="preselectCommercialAction.action_type === 0; else withCodePromo">
          <div class="ppp-interrogator card"
          *ngIf="allowToCreateAgreements; else remainingTemplate">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">search</i>
              </div>
              <h4 class="card-title">Interrogateur - Montant à déduire</h4>
            </div>
            <div class="card-body">
              <div class="ppp-commercialAction-counters">
                <!-- Always show national quotas -->
                <div class="national-quotas">
                  <strong>Quotas {{ preselectCommercialAction.adherent_id ? 'locaux' : 'nationaux' }}</strong><br>
                  <div>
                    Budget {{ preselectCommercialAction.adherent_id ? 'local' : 'national' }} :
                    <strong>{{ remainingNationalBudget }} / {{ preselectCommercialAction.budget }} € {{ preselectCommercialAction.universe.name === "b2c_vl" ? " TTC" : " HT" }}</strong>
                  </div>
                  <div>
                    Actions immédiates {{ preselectCommercialAction.adherent_id ? 'locales' : 'nationales' }} :
                    <strong>{{ remainingNationalImmediateActions }} / {{ preselectCommercialAction.immediate_actions_number }}</strong>
                  </div>
                </div>

                <!-- Show adherent-specific quotas if available -->
                <ng-container *ngIf="globalRemainingCheck">
                  <hr>
                  <div class="local-quotas">
                    <strong>Quotas additionnels pour {{getAdherentById(globalRemainingCheck.adherent_id).name}}*</strong><br>
                    <div>
                      Budget additionnel :
                      <strong>{{ globalRemainingCheck.budget - globalRemainingCheck.local_consumed_budget }} / {{ globalRemainingCheck.budget }} € {{ preselectCommercialAction.universe.name === "b2c_vl" ? " TTC" : " HT" }}</strong>
                    </div>
                    <div>
                      Actions immédiates additionnelles :
                      <strong>{{ globalRemainingCheck.immediate_actions_number - globalRemainingCheck.local_consumed_immediate_actions_number }} / {{ globalRemainingCheck.immediate_actions_number }}</strong>
                    </div>
                    <small>*Ces quotas seront utilisés si les quotas nationaux sont épuisés.</small>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- NO AGREEMENT CREATION ALERT -->
          <ng-template #remainingTemplate>
            <div class="card" *ngIf="editFormGroup.value.customer_agency">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">warning</i>
                </div>
                <h4 class="card-title">Impossible de créer un nouvel accord</h4>
              </div>
              <div class="card-body ppp-commercialAction-warning">
                <div>Il n'est plus possible de créer de nouveaux accords pour cette action commerciale. Soit :</div>
                <ul>
                  <li>Le nombre restant d'actions immédiates (national et/ou local) est écoulé ;</li>
                  <li>Le budget (national et/ou local) de cette action commerciale est atteint ;</li>
                </ul>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
        <ng-template #withCodePromo>
          <!-- BLOC "Code promotionnel" -->
          <div class="card" *ngIf="(preselectCommercialAction.action_type === 1 && f.customer_agency.value) || ([2, 3].includes(preselectCommercialAction.action_type) && actionType2Choice === 1)">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">warning</i>
              </div>
              <h4 class="card-title">Code promotionnel</h4>
            </div>
            <div class="card-body">
              <div class="form-group is-required">
                <label class="bmd-label-floating">Veuillez saisir un code promotionnel</label>
                <input type="text" name="promo_code" formControlName="promo_code" class="form-control">
                <small>Veuillez respecter la casse lors de la saisie du code (minuscules, majuscules).</small>
              </div>
              <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) en mode "Saisir un accord" -->
              <ng-container *ngIf="preselectCommercialAction.action_type == 3 && actionType2Choice == 1">
                <div class="form-group is-required">
                  <label for="customer_invoice_number_entered">Numéro de facture ou OR sur lequel le bon est utilisé</label>
                  <input type="text" name="customer_invoice_number_entered" formControlName="customer_invoice_number_entered" class="form-control">
                </div>
              </ng-container>  
            </div>
          </div>
        </ng-template>

      </div>
    </div>

    <ng-container *ngIf="!loadingCommercialActionCount">
      
      <!-- BLOC "Génération ou saisie de code" -->
      <!-- Pour les AC "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
      <div *ngIf="preselectCommercialAction && [2, 3].includes(preselectCommercialAction.action_type) && editFormGroup.value.customer_agency" class="row col-lg-6 col-md-12">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">style</i>
            </div>
            <h4 class="card-title">Génération ou saisie de code</h4>
          </div>
          <div class="card-body">
            <!-- "Générer un code" -->
            <div class="col-lg-6" *ngIf="(preselectCommercialAction.id != 106 && preselectCommercialAction.id != 100 && preselectCommercialAction.id != 92) ||
                                        (preselectCommercialAction.id == 100 && user.is_admin) || (preselectCommercialAction.id == 92 && user.is_admin) || (preselectCommercialAction.id == 106 && user.is_admin)">
              <label for="agreement_generate_code">
                <input type="radio" id="agreement_generate_code" [(ngModel)]="actionType2Choice"
                [disabled]="acIsArchived(preselectCommercialAction) && !authService.currentUserValue.is_admin"
                [ngModelOptions]="{standalone: true}" [value]="0" name="generate" (ngModelChange)="updateForm()"> Générer un code
              </label>
            </div>
            <!-- "Saisir un code" -->
            <div class="col-lg-6">
              <label for="agreement_write_code">
                <input type="radio" id="agreement_write_code" [(ngModel)]="actionType2Choice"
                [ngModelOptions]="{standalone: true}" [value]="1" name="type" (ngModelChange)="updateForm()"> Saisir un code
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- BLOCS "Informations client" et "Informations action commerciale" -->
      <!-- Pour les AC "Standard" (0), mais aussi "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) en mode "Génération d'un d'accord" -->
      <div *ngIf="(preselectCommercialAction && allowToCreateAgreements && (preselectCommercialAction.action_type === 0 || ([2, 3].includes(preselectCommercialAction.action_type) && actionType2Choice === 0))); else elseBlock"
        class="row ppp-agreements-data">

        <!-- BLOC "Informations client" -->
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">assignment_ind</i>
              </div>
              <h4 class="card-title">Informations client</h4>
            </div>
            <div class="card-body" *ngIf="preselectCommercialAction">

              <!--Dynamic fields -->
              <ng-container *ngFor="let field of customerFields">
                <div class="row" *ngIf="getUserFieldFromCommercialAction(field.slug) !== null">
                  <div class="col-lg-12">
                    <div class="form-group" [ngClass]="{'is-required': getUserFieldFromCommercialAction(field.slug).required, 'has-danger': submitted && f['customer_' + field.slug] && f['customer_' + field.slug].errors}">

                      <ng-container [ngSwitch]="field.type">
                        <ng-container *ngSwitchCase="'address'">
                          <div>
                            <label for="adress">Adresse</label>
                            <input type="text" name="adress" class="form-control" [formControlName]="'customer_address'">
                            <label for="adress_add" class="force-no-required">Complément d'adresse</label>
                            <input type="text" name="adress_add" class="form-control" [formControlName]="'customer_address_add'">
                            <label for="adress_postal_code">Code postal</label>
                            <input type="text" NumbersOnly maxlength="5" name="adress_postal_code" class="form-control" [formControlName]="'customer_address_postal_code'">
                            <label for="adresse_city">Ville</label>
                            <input type="text" name="adress_city" class="form-control" [formControlName]="'customer_address_city'">
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'siret'">
                          <div>
                            <label for="adress">Siret</label>
                            <input type="text" name="adress" class="form-control" NumbersOnly maxlength="14" [formControlName]="'customer_' + field.slug">
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'radio'">
                          <label for="agreement_user_siret" class="bmd-label-floating">{{field.label}}</label>
                            <div class="form-group form-radio-group">
                              <ng-container *ngFor="let option of field.options">
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" [name]="'customer_' + field.slug" [formControlName]="'customer_' + field.slug" [value]="option.value"> {{option.label}}
                                    <span class="circle">
                                      <span class="check"></span>
                                    </span>
                                  </label>
                                </div>
                              </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'phone'">
                          <label for="agreement_user_siret" class="bmd-label-floating">{{field.label}}</label>
                          <input (paste)="false" NumbersOnly [allowDecimals]="false" type="tel" pattern="^((\+)33|0)[1-9](\d{2}){4}$" class="form-control" [id]="field.slug" [formControlName]="'customer_' + field.slug">
                          <ng-container *ngIf="f['customer_' + field.slug].value !== '' && f['customer_' + field.slug].errors">
                            <small>Remarque : veuillez saisir un numéro de téléphone portable valide</small>
                          </ng-container>
                          <small class="d-block">Ne pas saisir d'espaces.</small>
                        </ng-container>

                        <ng-container *ngSwitchCase="'email'">
                          <label for="agreement_user_siret" class="bmd-label-floating">{{field.label}}</label>
                          <input [type]="field.type" class="form-control" [id]="field.slug" pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]{2,61}(?:[a-zA-Z0-9-.][a-zA-Z0-9]{2,61})*$" [formControlName]="'customer_' + field.slug">
                          <ng-container *ngIf="f['customer_' + field.slug].value !== '' && f['customer_' + field.slug].errors">
                            <small>Remarque : veuillez saisir un email valide</small>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                          <label for="agreement_user_siret" class="bmd-label-floating">{{field.label}}</label>
                          <input [type]="field.type" class="form-control" [id]="field.slug" [formControlName]="'customer_' + field.slug">
                        </ng-container>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>

        <!-- BLOC "Informations action commerciale" -->
        <!-- Pour les AC "Standard" (0) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
        <div class="col-lg-6 col-md-12" *ngIf="[0,3].includes(preselectCommercialAction.action_type);">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">local_offer</i>
              </div>
              <h4 class="card-title">Informations action commerciale</h4>
            </div>
            <div class="card-body">
              <ng-container *ngFor="let field of generalFields">
                <div class="row" *ngIf="getGeneralFieldFromCommercialAction(field.slug) !== null">
                  <div class="col-lg-12">
                    <div class="form-group" [ngClass]="{'is-required': getGeneralFieldFromCommercialAction(field.slug).required, 'has-danger': submitted && f['general_' + field.slug] && f['general_' + field.slug].errors}">

                      <ng-container *ngIf="field.type === 'custom'; else fieldElse">
                        <!-- "NOMBRE DE PNEUS" -->
                        <ng-container *ngIf="field.slug === 'tire_count'">
                          <label>Nombre de pneus</label>
                          <select class="form-control custom-list" style="position:inherit;" formControlName="general_tire_count">
                            <option [value]="null">Choisir une valeur</option>
                            <option *ngFor="let item of [1, 2, 3, 4]" [value]="item">{{item}}</option>
                          </select>
                        </ng-container>
                        <!-- "MARQUE DE PNEU" -->
                        <ng-container *ngIf="tiresList && (field.slug === 'tire_brand' || field.slug === 'tire_brand_b_g_d')">
                          <app-datalist [items]="field.slug === 'tire_brand_b_g_d' ? tiresListSpecial : tiresList" [preselect]="preselectTire" label="Marque de pneu" (selected)="onSelectedTire($event)" [property]="'name'"></app-datalist>
                          <input type="hidden" name="" [formControlName]="'general_' + field.slug">
                        </ng-container>
                        <!-- "DIAMÈTRE DE PNEU" -->
                        <ng-container *ngIf="diametersList && field.slug === 'general_diameter'">
                          <label class="bmd-label-floating">Diamètre de pneu</label>
                          <select class="form-control custom-list" style="position:inherit;" formControlName="general_diameter">
                            <option [value]="null">Choisir une valeur</option>
                            <option *ngFor="let item of tc4(diametersList)" [value]="item">{{item}}</option>
                          </select>
                        </ng-container>
                      </ng-container>

                      <ng-template #fieldElse>
                        <label [for]="field.slug" class="bmd-label-floating">{{field.label}}</label>
                        <ng-container [ngSwitch]="field.type">
                          <ng-container *ngSwitchCase="'radio'">
                            <ng-container *ngFor="let option of field.options">
                              <input type="radio"
                                [formControlName]="'general_' + field.slug"
                                [name]="'general_' + field.slug"
                                [value]="option.value"/> {{option.label}}
                            </ng-container>
                          </ng-container>
                          <ng-container *ngSwitchCase="'number'">
                            <input type="number" NumbersOnly min="0" max="999" step="0.01" class="form-control" [id]="field.slug" [formControlName]="'general_' + field.slug">
                            <small class="d-block">Saisir un point (.) pour les décimales.</small>
                          </ng-container>
                          <ng-container *ngSwitchCase="'list'">
                            <select class="form-control" [formControlName]="'general_' + field.slug">
                              <option [ngValue]="null" disabled>Selectionnez </option>
                              <option *ngFor="let option of field.options" [ngValue]="option.value">{{option.label}}</option>
                            </select>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <input [type]="field.type" class="form-control" [id]="field.slug" [formControlName]="'general_' + field.slug">
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Bouton "Interroger" -->
              <!-- Pour les AC "Standard" (0) -->
              <div style="margin-top:1rem;">
                <div *ngIf="editFormGroup && preselectCommercialAction.action_type != 3">
                  <button [disabled]="editFormGroup.invalid" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="requestDiscount()">Interroger</button>
                </div>
                <div *ngIf="displayDiscount" class="ppp-interrogator-amount">
                  <div style="margin:1rem 0;">
                    <strong>
                      Montant à déduire : {{ discountEstimation !== null ? discountEstimation.toFixed(2) : '--' }} € {{ preselectCommercialAction.universe.name === 'b2c_vl' ? 'TTC' : 'HT' }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ng-template #elseBlock>
        <ng-container *ngIf="editFormGroup.value.customer_agency && [2, 3].includes(preselectCommercialAction.action_type) && actionType2Choice === 0">
          <div class="col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">warning</i>
                </div>
                <h4 class="card-title">Impossible de créer un nouvel accord</h4>
              </div>
              <div class="card-body ppp-commercialAction-warning">
                <div>Il n'est plus possible de créer de nouveaux accords pour cette action commerciale. Soit :</div>
                <ul>
                  <li>Le nombre (national et/ou local) d'actions immédiates restantes est écoulé ;</li>
                  <li>Le budget (national et/ou local) de cette action commerciale est atteint ;</li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>

      <!-- Bouton d'envoi -->
      <div *ngIf="preselectCommercialAction" class="row col-12">
        <input
          type="submit"
          [disabled]="(!displayDiscount && preselectCommercialAction.action_type === 0) ||
                      (!allowToCreateAgreements && [2, 3].includes(preselectCommercialAction.action_type) && actionType2Choice === 0) || editFormGroup.invalid"
          class="ppp-button ppp-button-blue btn btn-primary btn-round"
          *ngIf="!loadingService.loading; else templateSubmit"
          [value]="editMode.active ? 'Mettre à jour' : 'Créer'" (click)="onSubmit()">
        <ng-template #templateSubmit>
          <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">...</button>
        </ng-template>
      </div>
    </ng-container>
  </form>
</ng-container>
<ng-template #templateName>
  <app-loading></app-loading>
</ng-template>
